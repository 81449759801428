import React, { useContext } from 'react';

import WardContext from './WardContext';

import CityMap from "../components/CityMap"
import IconSelect from "../components/icons/IconSelect"
import { Link, navigate } from 'gatsby';

export default function CityMapHome({ headline }) {
  const [ currentWard, setCurrentWard ] = useContext(WardContext);

  // = = = = = = = = = = = = =
  // Data

  // Select Input Values
  const wardsArray = [
    "Aldersgate",
   "Aldgate",
   "Bassishaw",
   "Billingsgate",
   "Bishopsgate",
   "Bread_Street",
   "Bridge",
   "Broad_Street",
   "Candlewick",
   "Castle_Baynard",
   "Cheap",
   "Coleman_Street",
   "Cordwainer",
   "Cornhill",
   "Cripplegate",
   "Dowgate",
   "Farringdon_Within",
   "Farringdon_Without",
   "Langbourn",
   "Lime_Street",
   "Portsoken",
   "Queenhithe",
   "Tower",
   "Vintry",
   "Walbrook"
  ];

  // = = = = = = = = = = = = =
  // Utils

  // Add id to each Heading with title as a value
  const handleChange = (e) => {
    setCurrentWard(e.target.value);
    if(e.target.value === 'Farringdon_Without' || e.target.value === 'Portsoken' || e.target.value === 'Cripplegate') {
      navigate(`/find-your-ward`);
      return;
    }
    else if (e.target.value === 'Bridge') {
      navigate('/ward/bridge-and-bridge-without');  
      return;
    } else {
      navigate(`/ward/${e.target.value.toLowerCase().replace("_", "-")}`);
    }
  }

  return(
    <section id="city-map" className="section-map">
      <div className="container city-map">

        <div className="row">

          <div className="col-md-4 offset-md-1">
            <h3 className="city-map__headline">{ headline }</h3>

            <div className="city-map__select">
              <label htmlFor="select-ward" className="select__label">Or select it from the list below</label>
              <div className="select__wrapper">
                
                <select value={currentWard}  onChange={ (e) => handleChange(e) } className="select__input" name="ward" id="select-ward">
                  <option className="select__option" value="">Select</option>
                  {
                    wardsArray.map( ward => <option className="select__option" key={ ward } value={ ward }>{ward.replace("_"," ")}</option>)
                  }
                </select>
                
                <div className="select__icon">
                  <IconSelect />
                </div>

              </div>
            </div>
          </div>

          <div className="col-md-7">
            <CityMap currentWard={currentWard} setCurrentWard={setCurrentWard} />
          </div>

        </div>
        <div className="row">

          <div className="col-md-12 offset-md-1 mt-3">
            <p>Not sure which ward you're in? <Link to="/find-your-ward" >Find my ward ➝</Link></p>
          </div>

        </div>
      </div>
    </section>
  )
}