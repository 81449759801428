import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import showdown from "showdown";
import ReactMarkdown from 'react-markdown';


const displayCtas = ( ctas, justify ) => (
  <div className="hero__ctas">
    <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
      
      {
        ctas.map(cta => (
          <div key={ cta.button_label } className="col-md">
            <Link className="button button--100" to={cta.button_url}>
              { cta.button_label }
            </Link>
          </div>
        ))
      }

    </div>
  </div>
)

const SearchForm = ({handleChange, handleSubmit, filteredAddresses, notification, getElectionStatus}) => {
  return(
    <div className={ `row justify-content-center`}>
      <div className={ `hero__form col-md-8` }>

          {/* {
            ctas ? displayCtas(ctas, justify) : ''
          } */}
        
          <form onSubmit={(e) => handleSubmit(e) } action="">
            {
              getElectionStatus() === 'proceeding' ?
                <label htmlFor="">Find your candidates and where to vote</label>
              :
                <label htmlFor="">Where are you registered to vote?</label>
            }
            <div className="input-wrapper">
              <input onChange={handleChange} type="text" name="" id="" placeholder="Enter the name or address of your workplace or residence" />
              <input type="submit" value="Find my ward" />
            </div>
          </form>
          {
            filteredAddresses?.length > 0 
            
            &&

            <div className="addresses-wrapper">
              <ul className="addresses-list">
                {
                  filteredAddresses.map(property => (
                    <li 
                      data-address={property.address} 
                      key={property.address} 
                    >
                      <Link to={`/ward/${property.ward.toLowerCase().replace("(", "").replace(")", "").replace(/\s+/g, '-')}`}>
                        <span data-address={property.address} className="item-address">{property.address}</span> 
                        <span data-address={property.address} className="item-link">Select Address ›</span>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          }
          {
            notification && filteredAddresses?.length < 1 
              &&
            <p className={`form__notification ${notification ? 'show' : undefined}`}>{notification}</p>
          }

      </div>
    </div>
  )
}

export default function HeroSearch({ children, headline, intro, size, subhead, video, page, justify, ctas }) {
  const [propertyList, setPropertyList] = useState([]);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [notification, setNotification] = useState(null);
  const [electionsStatus, setElectionsStatus] = useState(null);

  const converter = new showdown.Converter();
  const htmlIntro = converter.makeHtml(intro);

  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves companies Data
  const fetchPropertyList = async () => {
    await axios.get('/.netlify/functions/getAddressWithWard')
      .then( (response) => {
        const { addressWithWard } = response.data;
        setPropertyList(addressWithWard);
      })
      .catch( error => console.log(error) )
  }

  useEffect( () => fetchPropertyList(), []);

  const getElectionStatus = () => {
    const currentdate = new Date(); 
    const date = currentdate.getDate();
    const month = currentdate.getMonth();
    const hours = currentdate.getHours();
    const minutes = currentdate.getMinutes();
    const seconds = currentdate.getSeconds();

    if((date === 24) && (month + 1 === 3) && ( hours < 20)) {
      return 'proceeding';
    } else if((date === 24) && (month + 1 === 3) && (hours >= 20) && (seconds > 0)) {
      return 'finished';
    }

  }


  // = = = = = = = = 
  // EventHandlers

  const handleChange = (e) => {
    e.preventDefault();
    filterData(e.target.value);
  }

  //  Returns company
  const searchData = (address, value) => {
    return (
      address?.toLowerCase()
        .replace("'", "")
        .replace("&", "and")
        .replace("-", "")
        .replace(/\s/g, "")
        .replace(",", "")
        .includes(value
          .toLowerCase()
          .replace("&", "and")
          .replace("-", "")
          .replace("'", "")
          .replace(/\s/g, "")
          .replace(",", "")
        )
    );
  }

  // Filter Data when query updates
  const filterData = (value) => {
    if(propertyList?.length < 1) return;
    if(value.length < 3) {
      setFilteredAddresses([]);
      setNotification(null);
      return;
    }
    
    const filteredAddress = propertyList?.filter(property => searchData(property.address, value));
    setFilteredAddresses(filteredAddress);

    if(filteredAddress?.length < 1) {
      setNotification('The address is not listed')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/wards');
  }


  return (
    <div className={`hero hero--search hero--${size} hero--${page ? page : ''}`} >
      {
        video
          ? <div className="hero__background-video">
              <video autoPlay loop muted playsInline>
                <source src={ video } type="video/mp4" />
              </video>
            </div>
          : ''
      }
      { 
        !video  && children
      }
      <div className={ `container d-flex flex-column justify-content-between`}>
        

        <div className={ `row justify-content-center text-center`}>
          <div className={`hero__headline-wrapper col-md-10`}>
            {
              subhead ? <h2 className="hero__subtitle">{ subhead }</h2> : ''
            }
            <h1 className="hero__headline">Your chance to shape the City’s future</h1>
            
            { intro ? <ReactMarkdown className="hero__intro">{htmlIntro}</ReactMarkdown> : '' }

          </div>
          {
            children[1] ? children[1] : ''
          }
        </div>


        {
          // getElectionStatus() === 'finished' ?
          //   <div className="col-md-12 d-flex justify-content-center">
          //       <a className="button button--live" href="/results/">
          //         Results
          //       </a>
          //   </div>
          // :
            <>
              <SearchForm 
                handleChange={handleChange} 
                handleSubmit={handleSubmit} 
                filteredAddresses={filteredAddresses} 
                notification={notification}
                getElectionStatus={getElectionStatus}
              />
              <div className={ `row justify-content-center text-center`}>
                <div className={ `hero__links col-12` }>
                  
                    <Link to="#city-map">Click here to see a map of wards</Link>

                </div>
              </div>
            </>
        }


      </div>
    </div>
  )
}